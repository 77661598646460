import { Component, OnInit } from '@angular/core';
import { AuthService, ConfigStateService, ReplaceableComponentsService } from '@abp/ng.core'; // imported ReplaceableComponentsService
import { eThemeBasicComponents, LogoComponent, NavItemsComponent } from '@abp/ng.theme.basic'; // imported eThemeBasicComponents
import { Router } from '@angular/router';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { SettingsTabComponent } from './settings-tab/media-file-tab/settings-tab.component';
import { SupportVideosTabComponent } from './settings-tab/support-videos-tab/support-videos-tab.component';
import { MarketingTabComponent } from './settings-tab/marketing-tab/marketing-tab.component';
import * as Sentry from "@sentry/angular-ivy";
import { UrlForwardingTabComponent } from './settings-tab/url-forwarding-tab/url-forwarding-tab.component';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	constructor(
		private replaceableComponents: ReplaceableComponentsService,
		private router: Router,
		private _settingTabs: SettingTabsService,
		private authService: AuthService,
		private configStateService: ConfigStateService,
		private oA: OAuthService
	) { } // injected ReplaceableComponentsService

	async ngOnInit() {
		this.configStateService.getOne$('currentUser').subscribe(async user => {
			if (user.isAuthenticated) {
				try {
					const newAccessToken = await this.oA.refreshToken();
					if (!newAccessToken.access_token) {
						this.oA.logOut();
						this.authService.navigateToLogin();
					}
				}
				catch {
					this.oA.logOut();
					this.authService.navigateToLogin();
				}
				Sentry.setContext("userInfo", user);
				Sentry.setTag("userId", user.id);
				Sentry.setTag("userName", user.userName);
				Sentry.setTag("email", user.email);
				Sentry.setUser({ id: user.id, username: user.userName, email: user.email });
			}
		});

		this._settingTabs.add([
			{
				name: 'Media',
				component: SettingsTabComponent,
			},
			{
				name: 'Support Videos',
				component: SupportVideosTabComponent,
			},
			{
				name: 'Marketing',
				component: MarketingTabComponent,
			},
			{
				name: 'Url Forwarding',
				component: UrlForwardingTabComponent,
			},
		]);

		this.replaceableComponents.add({
			component: LogoComponent,
			key: eThemeBasicComponents.Logo,
		});

		this.replaceableComponents.add({
			component: NavItemsComponent,
			key: eThemeBasicComponents.NavItems,
		});
	}
}
